<template>
  <el-dialog
    title="Filter"
    :visible.sync="reportsStore.filterDialog"
    width="35%"
    top="30px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    align="center"
    ref="elDialogRef"
  >
    <el-row :gutter="8" class="filter-body">
      <el-col :span="24">
        <el-form :model="form">
          <!-- ? -->
          <el-form-item>
            <el-col :span="24">
              <el-form-item
                prop="contract__client__full_name__icontains"
                label="Buyurtmachi kompaniya"
              >
                <el-input
                  clearable
                  v-model="form.contract__client__full_name__icontains"
                  placeholder="Buyurtmachi kompaniya"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <!--  -->

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="truck__truck_type__model__icontains"
                label="Mashina (model)"
              >
                <el-input
                  clearable
                  v-model="form.truck__truck_type__model__icontains"
                  placeholder="Mashina (model)"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="truck__truck_type__brand__name__icontains"
                label="Mashina (brand)"
              >
                <el-input
                  clearable
                  v-model="form.truck__truck_type__brand__name__icontains"
                  placeholder="Mashina (brand)"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="truck__reg_number__icontains"
                label="Gos. nomer"
              >
                <el-input
                  clearable
                  v-model="form.truck__reg_number__icontains"
                  placeholder="Gos. nomer"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="start_date2" label="Chiqish kuni">
                <el-input
                  clearable
                  v-model="form.start_date2"
                  placeholder="Chiqish kuni"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <!--  -->

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="truck__truck_type__capacity_kg"
                label="Tonnaj"
              >
                <el-input
                  clearable
                  v-model="form.truck__truck_type__capacity_kg"
                  placeholder="Tonnaj"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="post_m3" label="M3">
                <el-input
                  clearable
                  v-model="form.post_m3"
                  placeholder="M3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <!--  -->

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="post_kg" label="Заказной тон/кг">
                <el-input
                  clearable
                  v-model="form.post_kg"
                  placeholder="Заказной тон/кг"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Пункт отправки">
                <el-input
                  clearable
                  v-model="form.destinations['1'].city_name"
                  placeholder="Пункт отправки"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item label="Пункт назначения 1">
                <el-input
                  clearable
                  v-model="form.destinations['2'].city_name"
                  placeholder="Пункт назначения 1"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Пункт назначения 2">
                <el-input
                  clearable
                  v-model="form.destinations['3'].city_name"
                  placeholder="Пункт назначения 2"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item label="Пункт назначения 3">
                <el-input
                  clearable
                  v-model="form.destinations['4'].city_name"
                  placeholder="Пункт назначения 3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Пункт назначения 4">
                <el-input
                  clearable
                  v-model="form.destinations['5'].city_name"
                  placeholder="Пункт назначения 4"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <!--  -->

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="price" label="Сумма BTS">
                <el-input
                  clearable
                  v-model="form.price"
                  placeholder="Сумма BTS"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="postavshik_price" label="Сумма поставщика">
                <el-input
                  clearable
                  v-model="form.postavshik_price"
                  placeholder="Сумма поставщика"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <!--  -->

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="nds_summa" label="НДС BTS">
                <el-input
                  clearable
                  v-model="form.nds_summa"
                  placeholder="НДС BTS"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="postavshik_nds_summa" label="НДС поставщика">
                <el-input
                  clearable
                  v-model="form.postavshik_nds_summa"
                  placeholder="НДС поставщика"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <!--  -->

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="price_with_nds" label="Сумма с НДС BTS">
                <el-input
                  clearable
                  v-model="form.price_with_nds"
                  placeholder="Сумма с НДС BTS"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="postavshik_price_with_nds"
                label="Сумма с НДС поставщика"
              >
                <el-input
                  clearable
                  v-model="form.postavshik_price_with_nds"
                  placeholder="Сумма с НДС поставщика"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <!--  -->

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="comment__icontains" label="Yuk ma'lumoti">
                <el-input
                  clearable
                  v-model="form.comment__icontains"
                  placeholder="Yuk ma'lumoti"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="post_m3" label="Hajm">
                <el-input
                  clearable
                  v-model="form.post_m3"
                  placeholder="Hajm"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <!--  -->

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="deliver__icontains" label="Postavchik">
                <el-input
                  clearable
                  v-model="form.deliver__icontains"
                  placeholder="Postavchik"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="zayavka_ttn__reg_number__icontains"
                label="TTNK #"
              >
                <el-input
                  clearable
                  v-model="form.zayavka_ttn__reg_number__icontains"
                  placeholder="TTNK #"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <!--  -->

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="main_driver__staff__staffpersonal__first_name__icontains"
                label="Asosiy haydovchi (Ism)"
              >
                <el-input
                  clearable
                  v-model="
                    form.main_driver__staff__staffpersonal__first_name__icontains
                  "
                  placeholder="Asosiy haydovchi (Ism)"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="main_driver__staff__staffpersonal__last_name__icontains"
                label="Asosiy haydovchi (Familiya)"
              >
                <el-input
                  clearable
                  v-model="
                    form.main_driver__staff__staffpersonal__last_name__icontains
                  "
                  placeholder="Asosiy haydovchi (Familiya)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <!--  -->

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="assistant_driver__staff__staffpersonal__first_name__icontains"
                label="Yordamchi haydovchi (Ism)"
              >
                <el-input
                  clearable
                  v-model="
                    form.assistant_driver__staff__staffpersonal__first_name__icontains
                  "
                  placeholder="Yordamchi haydovchi (Ism)"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="assistant_driver__staff__staffpersonal__last_name__icontains"
                label="Yordamchi haydovchi (Familiya)"
              >
                <el-input
                  clearable
                  v-model="
                    form.assistant_driver__staff__staffpersonal__last_name__icontains
                  "
                  placeholder="Yordamchi haydovchi (Familiya)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="zayavka_distance" label="Km">
                <el-input
                  clearable
                  v-model="form.zayavka_distance"
                  placeholder="Km"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="postavshik_driver__icontains"
                label="Postavchik haydovchi"
              >
                <el-input
                  clearable
                  v-model="form.postavshik_driver__icontains"
                  placeholder="Postavchik haydovchi"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">Tozalash</el-button>
      <el-button @click="onSubmit" type="primary" style="color: white"
        >Izlash</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { takeFilledKeyValue } from '@/utils'

const formDefaultKeys = () => ({
  start_date2: null,
  contract__client__full_name__icontains: null,
  truck__truck_type__model__icontains: null,
  truck__reg_number__icontains: null,
  truck__truck_type__capacity_kg: null,
  post_m3: null,
  post_kg: null,
  direction__contractdirectioncities__city__name__icontains: null,
  price: null,
  postavshik_price: null,
  nds_summa: null,
  postavshik_nds_summa: null,
  price_with_nds: null,
  postavshik_price_with_nds: null,
  comment__icontains: null,
  deliver__icontains: null,
  zayavka_ttn__reg_number__icontains: null,
  zayavka_distance: null,
  main_driver__staff__staffpersonal__first_name__icontains: null,
  assistant_driver__staff__staffpersonal__first_name__icontains: null,
  postavshik_driver__icontains: null,

  destinations: {
    1: {
      city_name: '',
      order: 1
    },

    2: {
      city_name: '',
      order: 2
    },

    3: {
      city_name: '',
      order: 3
    },

    4: {
      city_name: '',
      order: 4
    },

    5: {
      city_name: '',
      order: 5
    }
  }
})

export default {
  data() {
    return {
      form: {
        ...formDefaultKeys()
      }
    }
  },

  computed: {
    ...mapState(['reportsStore', 'filterDialog'])
  },

  methods: {
    ...mapMutations(['FILTER_DIALOG_TOGGLE', 'SET_FILTER', 'SET_QUERY_PARAM']),

    onCancel() {
      this.form = {
        ...formDefaultKeys()
      }

      const params = this.combineParams()

      this.$parent.$emit('filter:form', params)
      this.hideModal()
    },

    onSubmit() {
      try {
        const params = this.combineParams()

        this.$parent.$emit('filter:form', params)
      } catch (err) {
        //
      }

      this.hideModal()
    },

    hideModal() {
      const modalWrapper = document.querySelector('.el-dialog__wrapper')

      if (modalWrapper) modalWrapper.scrollTop = 0

      this.FILTER_DIALOG_TOGGLE(false)
    },

    combineParams() {
      const { destinations, ...props } = this.form

      // console.log('props', props)

      let params = takeFilledKeyValue(Object.freeze({ ...props }))

      // console.log('params', params)

      const t = (Object.values(destinations || {}) || []).filter(
        (item) => item.city_name
      )

      if (t != 0) {
        params.destinations = JSON.stringify(t)
      }
      return params
    }
  }
}
</script>

<style>
/* .filter-body { */
/* border: 1px solid red; */
/* } */

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

[type='text']::-webkit-search-cancel-button,
[type='text']::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
</style>
